import React from "react"

import Layout from "../components/layout"
import Slider from "../components/slider"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql } from "gatsby"
import Share from "../components/share"

const SimplePage = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const twitterHandle = data.site.siteMetadata.social.twitter

  return (
    <Layout location={location}
            title={post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}>
      <article>
        <header>
          <Slider
            title={post.frontmatter.title}
            subtitle={post.frontmatter.description}
          />
        </header>

        <section className="mt-5 mb-5">
          <Container>
            <Row>
              <Col>
                <p><small>Frissítve: {post.frontmatter.date}</small></p>
              </Col>
            </Row>
            <Row>
              <Col dangerouslySetInnerHTML={{ __html: post.html }}/>
            </Row>
          </Container>
          <Share
            socialConfig={{
              twitterHandle,
              config: {
                url: `${siteUrl}${pageContext.slug}`,
                title: `${siteTitle}`,
              },
            }}
            tags={post.frontmatter.tags}
          />
        </section>

        {/*<Container className="text-center">*/}
        {/*  <Nav>*/}
        {/*    {previous && (*/}
        {/*      <Nav.Link href={previous.fields.slug} rel="prev">*/}
        {/*        ← {previous.frontmatter.title}*/}
        {/*      </Nav.Link>*/}
        {/*    )}*/}
        {/*    {next && (*/}
        {/*      <Nav.Link to={next.fields.slug} rel="next">*/}
        {/*        {next.frontmatter.title} →*/}
        {/*      </Nav.Link>*/}
        {/*    )}*/}
        {/*  </Nav>*/}
        {/*</Container>*/}
      </article>
    </Layout>
  )
}

export default SimplePage

export const pageQuery = graphql`
  query SimplePageQuery($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          twitter
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      frontmatter {
        title
        author
        date
        description
        tags
      }
    }
  }
`
