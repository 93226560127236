import React from "react"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"

const Share = ({ socialConfig, tags }) => (
  <Container>
    <Row>
      <Col>
        <hr/>
      </Col>
    </Row>
    <Row>
      <Col>
        {tags && tags.length ? (
          <div>
            {tags.map(tag => (
              <Link
                to={`/tags/${tag}/`}
                className="text-black-50"
                key={tag}
              >
                #{tag}
              </Link>
            ))}
          </div>
        ) : null}
      </Col>
      <Col className="text-right">
        <FacebookShareButton url={socialConfig.config.url} className="button is-outlined is-rounded facebook">
          <FacebookIcon size={32} round={false}/>
        </FacebookShareButton>
        <TwitterShareButton url={socialConfig.config.url} className="button is-outlined is-rounded twitter"
                            title={socialConfig.config.title} via={socialConfig.twitterHandle.split("@").join("")}
                            hashtags={tags}>
          <TwitterIcon size={32} round={false}/>
        </TwitterShareButton>
        <LinkedinShareButton url={socialConfig.config.url} className="button is-outlined is-rounded linkedin"
                             title={socialConfig.config.title}>
          <LinkedinIcon size={32} round={false}/>
        </LinkedinShareButton>
        <RedditShareButton url={socialConfig.config.url} className="button is-outlined is-rounded reddit"
                           title={socialConfig.config.title}>
          <RedditIcon size={32} round={false}/>
        </RedditShareButton>
        <WhatsappShareButton url={socialConfig.config.url} className="button is-outlined is-rounded whatsapp"
                             title={socialConfig.config.title}>
          <WhatsappIcon size={32} round={false}/>
        </WhatsappShareButton>
        <ViberShareButton url={socialConfig.config.url} className="button is-outlined is-rounded whatsapp"
                          title={socialConfig.config.title}>
          <ViberIcon size={32} round={false}/>
        </ViberShareButton>
        <EmailShareButton url={socialConfig.config.url} className="button is-outlined is-rounded whatsapp"
                          title={socialConfig.config.title}>
          <EmailIcon size={32} round={false}/>
        </EmailShareButton>
      </Col>
    </Row>
  </Container>
)

export default Share
