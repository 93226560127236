import React from "react"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

const Slider = ({ title, subtitle }) => {
  return (
    <Container className="text-primary text-center mb-3 mt-3">
      <Row>
        <Col>
          <h2 className="animated fadeInUp">{title}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 className="animated fadeInUp">{subtitle}</h5>
        </Col>
      </Row>
    </Container>
  )
}

export default Slider
